.slick-prev {
  cursor: pointer;
  background: #f2f3f4;
  width: 45px;
  height: 45px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  color: #7e7e7e;
  top: 42%;
  left: 15px;
}

.slick-next {
  cursor: pointer;
  background: #f2f3f4;
  width: 45px;
  height: 45px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  color: #7e7e7e;
  top: 42%;
  right: 15px;
}

.slick-next:hover,
.slick-prev:hover {
  background-color: #3bb77e;
}
.slick-dots {
  display: flex !important;
}
