.slider-nav-thumbnails .slick-slide img {
  padding: 20px !important;
}

.detail-gallery .product-image-slider img {
  padding: 30px !important;
}

.stock-status.out-stock {
  color: #fff;
  background: #f59758;
}

.product-main-image-slider {
  display: flex !important;
  justify-content: center;
}
