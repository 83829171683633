.card-1 {
  height: 200px;
}

.card-1 figure img {
  max-width: 80px;
  width: 100%;
}

.card-1 figure {
  height: 45%;
}
