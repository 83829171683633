body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.banner-img .banner-text {
  top: 30% !important;
}

.banner-image {
  object-fit: fill;
  border-radius: 15px;
  height: 520px;
}

.banner-img.style-2 {
  background-size: contain;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .banner-image {
    height: 200px;
  }

  .banner-img.style-2 {
    height: 200px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .banner-image {
    height: 200px;
  }
  .banner-img.style-2 {
    height: 200px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .banner-image {
    height: 320px;
  }

  .banner-img.style-2 {
    height: 360px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .banner-image {
    height: 360px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .banner-image {
    height: 520px;
  }
}
